import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';

function RNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="blue" variant="dark" className="bg-blue">
      <Container>
        <Navbar.Brand href="#home" style={{ color: '#000000', fontFamily: 'Lobster', fontSize: '20px' }} >Care Transmission and Auto Repair</Navbar.Brand>
        
      </Container>
    </Navbar>
  );
}

export default RNavbar;

