import React from 'react';

import Header from "./Header"
import Mission from "./Mission";

function Home() {
  return (
    <>
      <Header />
      <Mission />
    </>
  );
}

export default Home;
