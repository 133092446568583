import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import "/Users/festobwogi/Desktop/Care Website/src/styles/styles.css"
//import "./styles/styles.css"
import { SocialIcon } from 'react-social-icons'

export default function Contact() {
    
    return (
        <footer className="site-footer" style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px' }}>
            <div className="footer-content" style={{ width: '100%' }}>
                <div className="contact-us" style={{ textAlign: 'left' }}>

                <h3>Contact </h3>
                <p>Email: caretransautorepair@gmail.com</p>
                <p>Phone: (424) 246-1278</p>
                <p>Address: 1635 Centinela Ave, Inglewood, CA 90302</p>
                <p>
                    <SocialIcon url="https://www.instagram.com/caretransautorepair/?igsh=MWN4ZzI1bDA1ZDdzNw%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" />

                </p>
            </div>
            
        </div>
    </footer>
    );
}


