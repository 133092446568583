import React from "react";
import About from "./Components/About";
import Navbar from "./Components/newNavbar";
import Header from "./Components/Header"
import Contact from "./Components/Contact";
import ContactForm from "./Components/Email"
import Mission from "./Components/Mission";
import Home from "./Components/Home";

import { BrowserRouter as Router } from "react-router-dom";

import "./styles/styles.css"

import backgroundImage from "/Users/festobwogi/Desktop/Care Website/src/images/IMG_2392.jpg";

import { Routes, Route } from 'react-router-dom';

export default function App() {


  return (
    
    <main className="app" style={{backgroundColor: 'gold', fontFamily:'Lobster'}}>
      <Navbar />
      <Home/>
      <About />
      <Contact />

    </main>

  );
}
