import React from "react";
import styled from "styled-components";

const Section = styled.section`
  padding: 20px;
  background-color: goldenrod;
`;

const Container = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding-right: 24px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: left;

  @media (min-width: 768px) {
    text-align: right;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 500;
  color: white;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const Description = styled.p`
  margin-bottom: 2rem;
  font-size: 1.25rem;
  color: white;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
`;

function Mission() {
  return (
<Section id="about">
      <Container>
        <InfoContainer>
          <h1 style = {{textAlign: 'center', textDecoration: 'underline'}}> About Us </h1>

          <p style={{ color: 'black', fontFamily: 'Lobster', fontSize:'30px'}}> At Care Transmission and Auto Repair, our family-owned shop is dedicated to providing the highest quality care and service for your vehicle.  Here at Care, we believe in building relationships that last as long as the cars we nurture. Every client who walks through our doors becomes part of our family, assured of transparent communication and a commitment to their satisfaction. Drive in today and experience the Care difference, where we treat every vehicle as if it were our own.</p>
            
            




          <p>
          </p>
          <div className="flex justify-center"></div>
        </InfoContainer>

      </Container>
    </Section>
  );
}

export default Mission;