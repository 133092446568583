import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function About() {
  return (
    <div>
      <h1 style = {{textAlign: 'center', textDecoration: 'underline'}}> Services </h1>
      <p style = {{fontSize: '26px'}}> Our list of services include but are not limited to:</p>
        <ul style = {{fontSize: '20px'}}><i className="fas fa-wrench" style={{ color: 'white', marginRight: '8px' }}></i>Engine oil change and service</ul>
        <ul style = {{fontSize: '20px'}}><i className="fas fa-car" style={{ color: 'white', marginRight: '8px' }}></i>Tire change and rotation</ul>
        <ul style = {{fontSize: '20px'}}><i className="fas fa-oil-can" style={{ color: 'white', marginRight: '8px' }}></i>Transmission oil change and service</ul>
        <ul style = {{fontSize: '20px'}}><i className="fas fa-tools" style={{ color: 'white', marginRight: '8px' }}></i>Transmission rebuilding</ul>
        <ul style = {{fontSize: '20px'}}><i className="fas fa-truck" style={{ color: 'white', marginRight: '8px' }}></i>Fleet Inspection and servicing</ul>
        <ul style = {{fontSize: '20px'}}><i className="fas fa-car" style={{ color: 'white', marginRight: '8px' }}></i>General auto service and repair</ul>
        <ul style = {{fontSize: '20px'}}><i className="fas fa-calculator" style={{ color: 'white', marginRight: '8px' }}></i>Accounting services</ul>
    </div>
  );
}

export default About;
