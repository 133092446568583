import React from 'react';
import "/Users/festobwogi/Desktop/Care Website/src/styles/styles.css"
import { SocialIcon } from 'react-social-icons'

//import "./styles/styles.css"

import backgroundImage from "/Users/festobwogi/Desktop/Care Website/src/images/IMG_2392.jpg";
//import backgroundImage from "./images/image.jpeg"

export default function Header() {
    const headerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain", // or 'contain' depending on your needs
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh", // Adjust the height as needed
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        color: "white" // If you want to add text and need to change its color
      };
    
    return (
  
      <header style={headerStyle}>

        <div className="personal-links">
        
                
                

            </div>      
            
      </header>
    );
}; 

